exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atl-status-js": () => import("./../../../src/pages/atl-status.js" /* webpackChunkName: "component---src-pages-atl-status-js" */),
  "component---src-pages-blog-common-details-of-income-tax-calculation-js": () => import("./../../../src/pages/blog/common-details-of-income-tax-calculation.js" /* webpackChunkName: "component---src-pages-blog-common-details-of-income-tax-calculation-js" */),
  "component---src-pages-blog-how-to-calculate-tax-in-pakistan-js": () => import("./../../../src/pages/blog/how-to-calculate-tax-in-pakistan.js" /* webpackChunkName: "component---src-pages-blog-how-to-calculate-tax-in-pakistan-js" */),
  "component---src-pages-blog-how-to-file-tax-return-in-pakistan-js": () => import("./../../../src/pages/blog/how-to-file-tax-return-in-pakistan.js" /* webpackChunkName: "component---src-pages-blog-how-to-file-tax-return-in-pakistan-js" */),
  "component---src-pages-blog-salary-division-and-allowances-in-pakistan-js": () => import("./../../../src/pages/blog/salary-division-and-allowances-in-pakistan.js" /* webpackChunkName: "component---src-pages-blog-salary-division-and-allowances-in-pakistan-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ntn-status-js": () => import("./../../../src/pages/ntn-status.js" /* webpackChunkName: "component---src-pages-ntn-status-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tax-calculator-2022-2023-js": () => import("./../../../src/pages/tax-calculator-2022-2023.js" /* webpackChunkName: "component---src-pages-tax-calculator-2022-2023-js" */),
  "component---src-pages-tax-calculator-2023-2024-js": () => import("./../../../src/pages/tax-calculator-2023-2024.js" /* webpackChunkName: "component---src-pages-tax-calculator-2023-2024-js" */),
  "component---src-pages-tax-slabs-2022-2023-js": () => import("./../../../src/pages/tax-slabs-2022-2023.js" /* webpackChunkName: "component---src-pages-tax-slabs-2022-2023-js" */),
  "component---src-pages-tax-slabs-2023-2024-js": () => import("./../../../src/pages/tax-slabs-2023-2024.js" /* webpackChunkName: "component---src-pages-tax-slabs-2023-2024-js" */),
  "component---src-pages-tax-slabs-2024-2025-js": () => import("./../../../src/pages/tax-slabs-2024-2025.js" /* webpackChunkName: "component---src-pages-tax-slabs-2024-2025-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

